<template>
    <Breadcrumb page-title="External APIs" page-section="External APIs"> </Breadcrumb>
    <section class="content">
       <div class="row">
        <div class="col-md-12">
            <div class="box box-info">
                <div class="box-header with-border">
                    <i class="fa fa-sign-in"></i>
                    <h3 class="box-title text-right">OpenID Connect Configuration <span class="data-v-tooltip" data-v-tooltip="iService supports openID integration to simplify the agent login process. Enter your openID Connect credentials into this page and the agent login page will be updated to include the appropriate button" style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"> <i class="fa fa-question-circle text-info help-icon"></i>
                      </span></h3>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="">
                        <div class="col-md-12 mbottom-15">
                            <p> To use OpenID with iService, you need to first register your entity and obtain a Client ID that can be entered into iService. </p>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group bg-group">
                                    <label class="col-md-12 control-label text-left"><img src="/img/login-icon/ms-symbollockup_signin_dark.png"></label>
                                    <div class="col-md-12">
                                    <div class="group-bg group-input detail-g-borderbottom">
                                        <label class="col-md-12 control-label text-left group-input-label">Azure Client ID</label>
                                        <div class="col-md-12">    
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <input type="text" class="form-control">
                                                </div>
                                                <div class="col-md-2">
                                                Set New Value
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group-bg group-input detail-g-bordertop">
                                        <label class="col-md-12 control-label text-left group-input-label">Azure Tenant ID</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-6">
                                <div class="form-group bg-group">
                                    <label class="col-md-12 control-label text-left"><img src="/img/login-icon/btn_okta_signin_dark_normal_web.png"></label>
                                    <div class="col-md-12">
                                    <div class="group-bg group-input detail-g-borderbottom">
                                        <label class="col-md-12 control-label text-left group-input-label">Okta Client ID</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="group-bg group-input detail-g-bordertop">
                                        <label class="col-md-12 control-label text-left group-input-label">Okta Domain</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <div class="form-group bg-group">
                                    <label class="col-md-12 control-label text-left"><img src="/img/login-icon/btn_google_signin_dark_normal_web.png"></label>
                                    <div class="col-md-12">
                                    <div class="group-bg group-input">
                                        <label class="col-md-12 control-label text-left group-input-label">Google Client ID</label>
                                        <div class="col-md-12">
                                        <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div class="col-10 mt-3">
                            <!--v-if-->
                        </div>
                        <div class="clearfix"></div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
       </div>
        <div class="row">
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa fa-dollar"></i>
                         <h3 class="box-title text-right">Embedded Payment Configuration  <span class="data-v-tooltip" data-v-tooltip="To configure the Heartland Payment Gateway, enter the required credentials provided by Heartland. You can control access to payment screens using the Payment access right." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"> <i class="fa fa-question-circle text-info help-icon"></i>
                      </span></h3>
                    </div>
                    <div class="box-body">
                        <div class="row">
                        <div class="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group bg-group">
                                        <label class="col-md-12 control-label text-left"> <span class="bg-red p-2 d-inline-block">Heartland</span></label>
                                        <div class="col-md-12">
                                        <div class="group-bg group-input detail-g-borderbottom">
                                            <label class="col-md-12 control-label text-left group-input-label">Public Key</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="group-bg group-input detail-g-bordertop">
                                            <label class="col-md-12 control-label text-left group-input-label">Secret Key</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        
                            <div class="col-10 mt-3">
                                <!--v-if-->
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa fa-bar-chart"></i>
                         <h3 class="box-title text-right">Microsoft Graph API Azure App Configuration <span class="data-v-tooltip" data-v-tooltip="To configure the Heartland Payment Gateway, enter the required credentials provided by Heartland. You can control access to payment screens using the Payment access right." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"> <i class="fa fa-question-circle text-info help-icon"></i>
                      </span></h3>
                    </div>
                    <div class="box-body">
                        <div class="row">
                        <div class="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group bg-group"> 
                                        <label class="col-md-12 control-label text-left"><img src="/img/login-icon/msgraphapi.png"></label>
                                        <div class="col-md-12">
                                        <div class="group-bg group-input detail-g-borderbottom">
                                            <label class="col-md-12 control-label text-left group-input-label">Application (Client) ID</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="group-bg group-input detail-g-bordertop">
                                            <label class="col-md-12 control-label text-left group-input-label">Client Secret Value</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="group-bg group-input detail-g-bordertop">
                                            <label class="col-md-12 control-label text-left group-input-label">Directory (Tenant) ID</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        
                            <div class="col-10 mt-3">
                                <!--v-if-->
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa icon-c icon-ai-chatbot"></i>
                         <h3 class="box-title text-right">AI Configuration </h3>
                    </div>
                    <div class="box-body">
                        <div class="row">
                        <div class="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group bg-group">
                                        <label class="col-md-12 control-label text-left"> <span class="d-inline-block"><img src="/img/login-icon/openai.png"></span></label> 
                                       <div class="col-md-12">
                                        <div class="group-bg group-input detail-g-borderbottom">
                                            <label class="col-md-12 control-label text-left group-input-label">Open AI Secret Key</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="group-bg group-input detail-g-bordertop">
                                            <label class="col-md-12 control-label text-left group-input-label">Velocity Chat API Key</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-10 mt-3">
                                <!--v-if-->
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa icon-c icon-ai-chatbot"></i>
                         <h3 class="box-title text-right">Chat AI Configuration </h3>
                    </div>
                    <div class="box-body">
                        <div class="row">
                        <div class="">
                            <div class="row">
                            
                                <div class="col-md-6">
                                    <div class="form-group bg-group">
                                        <label class="col-md-12 control-label text-left"> <span class="bg-primary text-white p-2 d-inline-flex chatbot-btn"><i class="fa icon-c icon-ai-chatbot pe-2"></i> <span>Chatbot Configuration</span></span></label> 
                                       <div class="col-md-12">
                                        <div class="group-bg group-input detail-g-borderbottom">
                                            <label class="col-md-12 control-label text-left group-input-label">Velocity Chat API Key</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="group-bg group-input detail-g-bordertop">
                                            <label class="col-md-12 control-label text-left group-input-label">Velocity Chat Introduction</label>
                                            <div class="col-md-12">
                                            <input type="text" class="form-control">
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        
                            <div class="col-10 mt-3">
                                <!--v-if-->
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            
            <div class="col-md-12 mt-3">
                <button type="button" class="btn btn-primary margin-r-5">Save</button>
            </div>
        </div>
    </section>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'

import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Criteria Settings',
   components: {
    Breadcrumb,
   
  }
  }
</script>